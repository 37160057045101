// @noflow
import initI18n from '@/packs/localisation'
import type { Language } from '@/packs/localisation'
import React from 'react'
import ReactDOM from 'react-dom'

import { DiscountBasis } from '@/utils/butternutbox/discountCodes'
import { initGoogleClickIdentifierSession } from '@/utils/googleClickIdentifierSession'
import { playVideosOnScroll } from '@/utils/playVideosOnScroll'
import * as Sentry from '@/utils/sentry'

import useWindowSize from '@/hooks/useWindowSize'

// Components
import segmentTrack from '@/components/analytics/Analytics'
import { initCookieConsent } from '@/components/cookie_consent'
import BreedSelector from '@/components/shared/elements/BreedSelector'
import { BreedSelectorVariant } from '@/components/shared/elements/BreedSelector/BreedSelector'
import RafCampaignModal from '@/components/shared/elements/RafCampaignModal'
import { RafCampaignModalData } from '@/components/shared/elements/RafCampaignModal/RafCampaignModal'
import RafModal from '@/components/shared/elements/RafModal'
import { RafModalData } from '@/components/shared/elements/RafModal/RafModal'
import FullSectionRoughCostCalculator from '@/components/shared/elements/RoughCostCalculator/FullSectionRoughCostCalculator'
import WonkyTitleText from '@/components/shared/elements/WonkyTitleText/WonkyTitleText'
import initGeoIpWidget from '@/components/shared/geo_ip_widget/initGeoIpWidget'
import { BREAKPOINTS } from '@/components/templates/Base'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type ReferralLinkType =
  | 'ClientReferralLink'
  | 'AmbassadorReferralLink'
  | 'InfluencerReferralLink'

const dataContainer = document.querySelector('#home-content-tag') as HTMLElement
if (!dataContainer)
  Sentry.captureException('Could not find #home-content-tag', {
    tags: {
      product: Sentry.Product.HomePage
    }
  })

const { dataset } = dataContainer
const {
  language,
  isPrewizardGuest,
  discountBasis,
  discountFirstPartValue,
  shippingCountryCode,
  shouldSeeNewHeroImage
} = dataset

const preferredLanguage = (language as Language) || 'en'

const initLanguage = (): void => {
  initI18n(preferredLanguage)
}

const initBreedSelector = (): void => {
  const breedSelectorElement =
    document.getElementsByClassName('breed-selector')[0]

  if (!breedSelectorElement) return

  const ctaDestination =
    isPrewizardGuest === 'true' ? '/wizard/new' : '/plans/recipes'

  const BreedSelectorComponent = () => {
    const { windowWidth } = useWindowSize()

    return (
      <BreedSelector
        isPreWizardGuest={isPrewizardGuest === 'true'}
        ctaDestination={ctaDestination}
        variant={BreedSelectorVariant.Homepage}
        scrollToOnFocus={
          windowWidth <= BREAKPOINTS.lg ? { topOffset: 60 } : false
        }
        screenIdentifier="homepage"
      />
    )
  }

  ReactDOM.render(<BreedSelectorComponent />, breedSelectorElement)
}

const initRafModal = (): void => {
  const rafModalElement = document.getElementById('raf-modal')
  if (!rafModalElement) return

  const referralLinkType = rafModalElement.dataset
    .referralLinkType as ReferralLinkType
  const { referrerRafCampaignName } = rafModalElement.dataset

  const validReferralLinkTypes: ReferralLinkType[] = [
    'ClientReferralLink',
    'AmbassadorReferralLink'
  ]

  // Check if the referral link is a client referral link and
  // if the referrer is part of the IFD 2024 RAF campaign
  if (
    referralLinkType === 'ClientReferralLink' &&
    referrerRafCampaignName &&
    referrerRafCampaignName === 'ifd-2024'
  ) {
    // Display RAF campaign modal
    const RafCampaignModalComponent = () => {
      return (
        <RafCampaignModal
          rafCampaignModalData={rafModalElement.dataset as RafCampaignModalData}
        />
      )
    }

    ReactDOM.render(<RafCampaignModalComponent />, rafModalElement)
  } else {
    // Otherwise display the standard RAF modal
    // Check if the supplied referralLinkType is one we can display the RAF modal for
    if (validReferralLinkTypes.every((link) => link !== referralLinkType))
      return
    const RafModalComponent = () => {
      return <RafModal rafModalData={rafModalElement.dataset as RafModalData} />
    }
    ReactDOM.render(<RafModalComponent />, rafModalElement)
  }
}

const initWonkyTitleText = (elementClassName: string, titleText: string) => {
  const wonkyTextElements = document.getElementsByClassName(elementClassName)

  const wonkyTextElementArray = [...wonkyTextElements]

  if (!wonkyTextElements) return null

  wonkyTextElementArray.forEach((element) => {
    ReactDOM.render(
      <WonkyTitleText namespace="home_page" text={titleText} />,
      element
    )
  })
}

const initRoughCostCalculator = () => {
  const roughCostCalculatorElement = document.getElementById(
    'rough-cost-calculator'
  ) as HTMLElement

  // We return null for users who aren't shown the calculator
  if (!roughCostCalculatorElement) return null

  ReactDOM.render(
    <FullSectionRoughCostCalculator
      preferredLanguage={preferredLanguage}
      screenIdentifier="homepage"
      discountBasis={discountBasis as DiscountBasis}
      discountValue={parseInt(discountFirstPartValue || '') || 0}
      shippingCountryCode={shippingCountryCode as CountryCode}
    />,
    roughCostCalculatorElement
  )
}

const draggableCarousel = (elementId: string) => {
  const element = document.getElementById(elementId)
  if (!element) return

  let pos = { top: 0, left: 0, x: 0, y: 0 }

  const mouseMoveHandler = function (e: MouseEvent) {
    element.scrollLeft = pos.left - (e.clientX - pos.x)
  }

  const mouseUpHandler = () => {
    document.removeEventListener('mousemove', mouseMoveHandler)
    document.removeEventListener('mouseup', mouseUpHandler)

    element.style.cursor = 'grab'
    element.style.removeProperty('user-select')
  }

  const mouseDownHandler = (e: MouseEvent) => {
    element.style.cursor = 'grabbing'
    element.style.userSelect = 'none'

    pos = {
      left: element.scrollLeft,
      top: element.scrollTop,
      x: e.clientX,
      y: e.clientY
    }

    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler)
  }

  element.addEventListener('mousedown', mouseDownHandler)
}

const trackSectionViewed = ({
  elementId,
  eventName,
  properties
}: {
  elementId: string
  eventName: string
  properties?: Record<string, unknown>
}) => {
  const element = document.getElementById(elementId)

  // If the user cannot see the element, don't track it
  if (!element) return null

  let hasTracked = false

  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting && !hasTracked) {
      segmentTrack(eventName, properties)
      hasTracked = true
      observer.disconnect()
    }
  })

  return observer.observe(element)
}

document.addEventListener('DOMContentLoaded', (): void => {
  initLanguage()

  initWonkyTitleText(
    'wonky-title-text',
    shouldSeeNewHeroImage === 'true'
      ? 'hero_section.title_v2'
      : 'hero_section.title'
  )
  initWonkyTitleText(
    'wonky-title-text-mobile',
    shouldSeeNewHeroImage === 'true'
      ? 'hero_section.title_v2'
      : 'hero_section.title_mobile'
  )
  initWonkyTitleText(
    'wonky-title-text-returning-guest',
    'hero_section.title_returning_guest'
  )
  initRoughCostCalculator()
  initGoogleClickIdentifierSession()
  initGeoIpWidget({
    desktopNav: true,
    mobileSidebar: true,
    contentTagElementId: 'home-content-tag'
  })
  playVideosOnScroll()
  initBreedSelector()
  initCookieConsent()
  initRafModal()
  draggableCarousel('ingredient-carousel')

  trackSectionViewed({
    elementId: 'fresh-section',
    eventName: 'Fresh ingredients section shown',
    properties: {
      path: window.location.pathname
    }
  })
  trackSectionViewed({
    elementId: 'how-it-works',
    eventName: 'How to get started section shown',
    properties: {
      path: window.location.pathname
    }
  })
  trackSectionViewed({
    elementId: 'unboxing-video',
    eventName: 'Unboxing video shown',
    properties: {
      path: window.location.pathname
    }
  })
  trackSectionViewed({
    elementId: 'inside-first-box',
    eventName: 'Welcome Box section shown',
    properties: {
      path: window.location.pathname
    }
  })
  trackSectionViewed({
    elementId: 'instagram-section',
    eventName: 'Butternutter social media section shown',
    properties: {
      path: window.location.pathname
    }
  })
  trackSectionViewed({
    elementId: 'rough-cost-calculator',
    eventName: 'Homepage Rough Cost Calculator Shown',
    properties: {
      path: window.location.pathname
    }
  })
  trackSectionViewed({
    elementId: 'frequently-asked-questions',
    eventName: 'FAQ section shown',
    properties: {
      path: window.location.pathname
    }
  })
})
